import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Container from '@mui/material/Container';
import axios from 'axios';
import {Button} from '../components/Button';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja';
import {format} from 'date-fns';
import {useLocation} from 'react-router-dom';
import CreateTable from '../components/checkLimit/CreateTable';
import Item from '../components/checkLimit/TweetItem';
import SelectedTweetArea from '../components/checkLimit/SelectedTweetArea';
import LimitExtendModal from '../components/checkLimit/LimitExtendModal';
import CheckDeletedTweetModal from '../components/checkLimit/CheckDeletedTweetModal';
import Modal from '@mui/material/Modal';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import {useNavigate} from 'react-router-dom';
import {StateConstantContext} from '../constants/StateConstant';
import IncrementalSearch from '../components/IncrementalSearch';
import {GetAuthorIdData} from '../components/getAuthorIdData';

export const CreateTableContext = React.createContext();
export const ExtendDateContext = React.createContext();
export const ModalHandlingContext = React.createContext();
export const TweetItemContext = React.createContext();

const CheckLimit = () => {
    const [overdueData, setOverdueData] = React.useState([]);
    const [overdueDataDisplay, setOverdueDataDisplay] = React.useState([]);
    const [selectedOverdueTweetID, setSelectedOverdueTweetID] = React.useState('');
    const [selectedRowsLength, setSelectedRowsLength] = React.useState('');
    const [selectedOverdueExpiredDate, setSelectedOverdueExpiredDate] = React.useState('');
    const [selectedOverduePostId, setSelectedOverduePostId] = React.useState('');
    const [selectedDate, setSelectedDate] = React.useState('');
    const [nowPage, setNowPage] = React.useState('0');
    const [maxPage, setMaxPage] = React.useState('-1');
    const [value, setValue] = React.useState(new Date());
    const [openSubmitModal, setOpenSubmitModal] = React.useState(false);
    const [openCheckModal, setOpenCheckModal] = React.useState(false);
    const [openSelectOptionModal, setOpenSelectOptionModal] = React.useState(false);
    const [postIdKey, setPostIdKey] = React.useState(''); // eslint-disable-line
    const [expiredAtKey, setExpiredAtKey] = React.useState(''); // eslint-disable-line
    const [rowId, setRowId] = React.useState('');
    const sub = 1;
    const submitHandleOpen = () => setOpenSubmitModal(true);
    const checkHandleOpen = () => setOpenCheckModal(true);
    const submitHandleClose = () => setOpenSubmitModal(false);
    const checkHandleClose = () => setOpenCheckModal(false);
    const selectOptionOpen = () => setOpenSelectOptionModal(true);
    const selectOptionClose = () => setOpenSelectOptionModal(false);
    const {authorIdX, authorIdDataX, setAuthorIdX, idToken} = React.useContext(StateConstantContext);
    const [authorIdDataXLabels, setAuthorIdDataXLabels] = React.useState('');
    const [authorIdDataXValues, setAuthorIdDataXValues] = React.useState('');
    const [tableKey, setTableKey] = React.useState('');
    const channel = 0;
    const navigate = useNavigate();
    const name = new URLSearchParams(useLocation().search).get('name');
    const url = process.env.REACT_APP_API_URL;
    const displayNum = 10;
    let array = [];

    React.useEffect(() => {
        getOverdueData();
    }, []);

    React.useEffect(() => {
        if (name) {
            setAuthorIdX(name);
        }
    }, []);

    React.useEffect(() => {
        if (authorIdDataX !== '') {
            setAuthorIdDataXLabels(authorIdDataX.map((item) => item.name));
            setAuthorIdDataXValues(authorIdDataX.map((item) => item.channelId));
        }
    }, [authorIdDataX]);

    React.useEffect(() => {
        const changeFlag = true; // authorIdまたはselectedDateが変更された場合はtrue
        if (authorIdX != '' && selectedDate != '') {
            setTableKey(authorIdX + selectedDate);
        }
        if (selectedDate == '') {
            setSelectedDate(getToday());
            return;
        }
        if (authorIdX != '') {
            navigate('/checklimit?name=' + authorIdX);
            setOverdueData([]);
            setExpiredAtKey('');
            setPostIdKey('');
            setOverdueDataDisplay([]);
            setNowPage(0);
            setMaxPage(-1);
            // nowPage = 0の時useEffectが発火しないため、ここでデータを取得する
            if (nowPage == 0) {
                getOverdueData(changeFlag);
            }
        }
    }, [authorIdX, selectedDate]);

    const getToday = () => {
        const d = new Date();
        const year = d.getFullYear();
        const month = d.getMonth() + 1;
        const date = d.getDate();
        const today = year + '-' + month.toString().padStart(2, '0') + '-' + date.toString().padStart(2, '0');
        return today;
    };

    React.useEffect(() => {
        if (maxPage < nowPage) {
            if (authorIdX != '') {
                getOverdueData();
            }
            setMaxPage(nowPage);
        } else {
            setOverdueDataDisplay(overdueData.slice(nowPage * displayNum, nowPage * displayNum + displayNum));
        }
    }, [nowPage]);

    const getOverdueData = async (changeFlag) => {
        if (authorIdX != '' && selectedDate != '') {
            const GET_OVERDUE_URL = url + 'overdue?author_id=' + authorIdX + '&expired_at=' + selectedDate + '&page=' + nowPage + '&limit=' + '10';

            if (idToken) {
                const response = await axios.get(GET_OVERDUE_URL, {headers: {Authorization: idToken}});
                if (response.status == 200) {
                    setOverdueDataDisplay(response.data.Items); // 期限切れツイート一覧表示用
                    if (changeFlag) {
                        array = createNewArray(response.data.Count);
                    } else {
                        nowPage == 0 ? (array = createNewArray(response.data.Count)) : (array = overdueData);
                    }
                    updateOverdueData(array, response.data.Items);
                }
            }
        }
    };

    const boxFieldStyles = {
        '.MuiOutlinedInput-notchedOutline': {
            borderRadius: '15px',
            borderColor: '#2E5077',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            border: 'solid 2px',
        },
    };

    const DatePickerDay = () => {
        const dateHandleChange = (newValue) => {
            const formatDay = format(newValue, 'yyyy-MM-dd');
            setSelectedDate(formatDay);
            setValue(newValue);
        };
        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
                dateFormats={{monthAndYear: 'yyyy年 MM月'}}
                localeText={{
                    previousMonth: '前月を表示',
                    nextMonth: '次月を表示',
                }}
            >
                <Box>
                    <DatePicker label='調べたい日付を選択' minDate={new Date()} defaultValue={new Date()} value={value} sx={boxFieldStyles} onChange={dateHandleChange} />
                </Box>
            </LocalizationProvider>
        );
    };

    const createNewArray = (total) => {
        // 空の配列を作成
        const emptyArray = new Array(total).fill({id: '', created_at: '', expired_at: '', text: '', post_id: ''});
        return [...emptyArray];
    };

    const updateOverdueData = (array, item) => {
        for (let i = 0; i < displayNum; i++) {
            const insertPos = nowPage * displayNum + i;
            if (item[i] != undefined) {
                array.splice(insertPos, 1, item[i]);
            }
            // 最終項目のidをkeyにセット
            if (i == 9) {
                if (item[i] != undefined) {
                    setPostIdKey(item[i].id);
                    setExpiredAtKey(item[i].expired_at);
                }
            }
        }
        setOverdueData(array);
    };

    return (
        <>
            <GetAuthorIdData channel={channel} />
            <Container sx={{marginTop: '8px'}}>
                <Grid container spacing={2}>
                    <Grid xs={0} sm={1}></Grid>
                    <Grid xs={12} sm={5}>
                        <Box sx={{width: 'auto', minWidth: 50, maxWidth: 300, margin: '0 auto'}}>
                            {/* <IncrementalSearch item={authorIdDataX} defaultValue={authorIdX} authorId={authorIdX} setAuthorId={setAuthorIdX} /> */}
                            <IncrementalSearch 
                                labelKeys={authorIdDataXLabels}
                                labelValues={authorIdDataXValues}
                                defaultLabelValue={authorIdX}
                                labelValue={authorIdX}
                                setLabelValue={setAuthorIdX}
                                description={"アカウントを選択"}
                            />
                        </Box>
                    </Grid>
                    <Grid xs={12} sm={5}>
                        <Box sx={{width: 'auto', minWidth: 50, maxWidth: 300, margin: '0 auto 20px'}}>
                            <DatePickerDay />
                        </Box>
                    </Grid>
                    <Grid xs={0} sm={1}></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={12} md={8}>
                        <Box sx={{width: '100%', height: 500}}>
                            <CreateTableContext.Provider
                                value={{
                                    overdueData,
                                    getOverdueData,
                                    selectedOverdueExpiredDate,
                                    setSelectedOverdueExpiredDate,
                                    selectedOverdueTweetID,
                                    setSelectedOverdueTweetID,
                                    selectedOverduePostId,
                                    setSelectedOverduePostId,
                                    selectedRowsLength,
                                    setSelectedRowsLength,
                                    nowPage,
                                    setNowPage,
                                    setExpiredAtKey,
                                    setPostIdKey,
                                    rowId,
                                    setRowId,
                                    tableKey,
                                }}
                            >
                                <CreateTable></CreateTable>
                            </CreateTableContext.Provider>
                        </Box>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <SelectedTweetArea selectedOverdueTweetID={selectedOverdueTweetID} selectedOverdueExpiredDate={selectedOverdueExpiredDate}></SelectedTweetArea>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid xs={8}></Grid>
                    <Grid
                        xs={3}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            onClick={submitHandleOpen}
                            sx={{
                                width: 200,
                                height: 50,
                                margin: '10 0 0 auto',
                            }}
                        >
                            <Box
                                sx={{
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: 18,
                                }}
                            >
                                期限修正
                            </Box>
                        </Button>
                    </Grid>
                    <Grid xs={1}></Grid>
                </Grid>

                <ModalHandlingContext.Provider value={{submitHandleOpen, submitHandleClose, openSubmitModal, checkHandleOpen, checkHandleClose, openCheckModal}}>
                    <ExtendDateContext.Provider value={{selectedOverdueTweetID, getOverdueData}}>
                        <LimitExtendModal
                            selectedRowsLength={selectedRowsLength}
                            selectedOverdueTweetID={selectedOverdueTweetID}
                            selectedOverduePostId={selectedOverduePostId}
                            selectedOverdueExpiredDate={selectedOverdueExpiredDate}
                        ></LimitExtendModal>
                        <CheckDeletedTweetModal
                            selectedRowsLength={selectedRowsLength}
                            selectedOverdueTweetID={selectedOverdueTweetID}
                            selectedOverdueExpiredDate={selectedOverdueExpiredDate}
                            authorId={authorIdX}
                            sub={sub}
                        ></CheckDeletedTweetModal>
                    </ExtendDateContext.Provider>
                </ModalHandlingContext.Provider>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        padding: '60 0 40 0',
                    }}
                    justifyContent='center'
                >
                    <TweetItemContext.Provider
                        value={{rowId, setRowId, setSelectedOverdueTweetID, setOpenSelectOptionModal, selectOptionOpen, setSelectedRowsLength, setSelectedOverduePostId, setSelectedOverdueExpiredDate}}
                    >
                        {overdueDataDisplay.length != 0 && overdueDataDisplay.map((e, i) => <Item key={e.id} tweet_id={e.id} limit={e.expired_at} index={i} page={nowPage} post_id={e.post_id} />)}
                    </TweetItemContext.Provider>
                </Box>
                <Modal open={openSelectOptionModal} onClose={selectOptionClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: 500,
                            height: '500px',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '20px',
                            backgroundColor: 'background.paper',
                            fontWeight: 'bold',
                            boxShadow: 24,
                            p: 4,
                            overflow: 'scroll',
                            borderRadius: '15px',
                            outline: 'none',
                            border: 'none',
                        }}
                    >
                        <Box sx={{fontSize: '25px', fontWeight: 'bold', textAlign: 'center'}}>この投稿の期限を修正しますか？</Box>
                        <Box sx={{textAlign: 'center'}}>
                            <Button
                                variant='outlined'
                                sx={{
                                    width: 200,
                                    margin: '10px',
                                }}
                                onClick={() => {
                                    submitHandleOpen();
                                }}
                            >
                                期限修正に進む
                            </Button>
                        </Box>
                        <Box justifyContent='center' sx={{width: '500px', marginTop: '10'}}>
                            <TwitterTweetEmbed id={selectedOverdueTweetID} tweetId={selectedOverdueTweetID} />
                        </Box>
                    </Box>
                </Modal>
            </Container>
        </>
    );
};
export default CheckLimit;
