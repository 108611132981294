import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {styled, lighten, darken} from '@mui/system';
// import {StateConstantContext} from '../constants/StateConstant';
import {Auth} from 'aws-amplify';

const GroupHeader = styled('div')(({theme}) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'light' ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function toHalfWidth(str) {
    str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
    return str;
}

export default function IncrementalSearch(props) {
    Auth.currentSession(); // IDトークンが切れている場合は更新
    let options = [];
    if (props.labelKeys.length == props.labelValues.length && props.labelValues.length != 0 && props.labelValues != undefined) {
        options = props.labelKeys
            .map((labelKey, i) => {
                if (labelKey) {
                    const firstLetter = toHalfWidth(labelKey[0]).toUpperCase();
                    return {
                        firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
                        key: labelKey,
                        value: props.labelValues[i],
                    };
                }
                return;
            }).filter((value) => value != undefined);
    }
    const handleChange = (value) => {
        props.setLabelValue(value);
    };
    return (
        <Autocomplete
            options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.key}
            loading={true}
            loadingText='Now loading ...'
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.description}
                    sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                            borderRadius: '15px',
                            borderColor: '#2E5077',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            border: 'solid 2px',
                        },
                    }}
                />
            )}
            renderGroup={(params) => (
                <li key={params.key}>
                    <GroupHeader>{params.group}</GroupHeader>
                    <GroupItems>{params.children}</GroupItems>
                </li>
            )}
            onChange={(event, option) => {
                if (event.target.value == 0) {
                    handleChange(option.value);
                }
            }}
            value={options.find((e) => e.value == props.LabelValue) ?? options.find((e) => e.value == props.defaultLabelValue) ?? null}
        />
    );
}
