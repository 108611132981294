import {Link as ReactLink} from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Container from '@mui/material/Container';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SellIcon from '@mui/icons-material/Sell';
import React from 'react';
import cockpitIcon from '../../../data/img/cockpit-blue-500x100.svg';

const LinkBlock = styled(Link)(({theme}) => ({
    textAlign: 'center',
}));

const Top = () => {
    React.useEffect(() => {
        const head = document.getElementsByTagName('head')[0];
        const scriptUrl = document.createElement('script');
        scriptUrl.type = 'text/javascript';
        scriptUrl.src = 'https://unpkg.com/amazon-quicksight-embedding-sdk@2.0.1/dist/quicksight-embedding-js-sdk.min.js';
        head.appendChild(scriptUrl);
    }, []);

    return (
        <Container width='70%'>
            <Grid container justifyContent='center' sx={{margin: '0 0 70 0', textAlign: 'center'}}>
                <Grid item xs={12}>
                    <Box sx={{margin: '10 0 20 0', fontWeight: 'bold', fontSize: 32}}>SNS運用支援ツール</Box>
                </Grid>
                <Grid item xs={12}>
                    <img src={cockpitIcon} alt='cockpit' style={{margin: '0 auto', width: '70%', maxWidth: '600px'}}></img>
                </Grid>
            </Grid>
            <Grid container justifyContent='center' sx={{textAlign: 'center', margin: '0 0 80 0'}}>
                <Box sx={{margin: '10 0 20 0', fontWeight: 'bold', fontSize: 24}}>以下のメニューから選択してください</Box>
                <Grid container rowSpacing={2}></Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <Box justifyContent='center' alignItems='center' display='flex'>
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            display='flex'
                            sx={{
                                width: '230px',
                                minWidth: '190px',
                                height: '180px',
                                margin: {xs: '0 0 20px', lg: '0 20'},
                                borderRadius: '30px',
                                border: 'solid 3px #aaa',
                                background: '#fff',
                            }}
                        >
                            <LinkBlock
                                component={ReactLink}
                                to='/analysis'
                                color='primary'
                                underline='none'
                                sx={{
                                    width: '230px',
                                    minWidth: '190px',
                                }}
                            >
                                <Button
                                    sx={{
                                        borderRadius: '20px',
                                        width: '100%',
                                    }}
                                    variant='standard'
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '170px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                padding: '8 0 0 0',
                                            }}
                                        >
                                            <AssessmentIcon sx={{fontSize: 120}} />
                                        </Box>
                                        <Box
                                            sx={{
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                            }}
                                        >
                                            ダッシュボード
                                        </Box>
                                    </Box>
                                </Button>
                            </LinkBlock>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <Box justifyContent='center' alignItems='center' display='flex'>
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            display='flex'
                            sx={{
                                width: '230px',
                                minWidth: '190px',
                                height: '180px',
                                margin: '0 20',
                                borderRadius: '30px',
                                border: 'solid 3px #aaa',
                                background: '#fff',
                            }}
                        >
                            <LinkBlock
                                component={ReactLink}
                                to='/tags'
                                color='primary'
                                underline='none'
                                sx={{
                                    width: '230px',
                                    minWidth: '190px',
                                }}
                            >
                                <Button
                                    sx={{
                                        borderRadius: '20px',
                                        width: '100%',
                                    }}
                                    variant='standard'
                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: '170px',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                padding: '8 0 0 0',
                                            }}
                                        >
                                            <SellIcon sx={{fontSize: 120}} />
                                        </Box>
                                        <Box
                                            sx={{
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                            }}
                                        >
                                            タグ付け
                                        </Box>
                                    </Box>
                                </Button>
                            </LinkBlock>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <Box justifyContent='center' alignItems='center' display='flex'>
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            display='flex'
                            sx={{
                                width: '230px',
                                minWidth: '190px',
                                height: '180px',
                                margin: '0 30',
                                borderRadius: '30px',
                                border: 'solid 3px #aaa',
                                background: '#fff',
                            }}
                        >
                            <LinkBlock
                                component={ReactLink}
                                to='/checklimit'
                                color='primary'
                                underline='none'
                                sx={{
                                    width: '230px',
                                    minWidth: '190px',
                                }}
                            >
                                <Button
                                    sx={{
                                        borderRadius: '20px',
                                        width: '100%',
                                        padding: '10 40',
                                    }}
                                    variant='standard'
                                >
                                    <Box>
                                        <Box>
                                            <AccessAlarmIcon sx={{fontSize: 120}} />
                                        </Box>
                                        <Box
                                            sx={{
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                            }}
                                        >
                                            投稿期限
                                        </Box>
                                    </Box>
                                </Button>
                            </LinkBlock>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6} md={6} lg={3}>
                    <Box justifyContent='center' alignItems='center' display='flex'>
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            display='flex'
                            sx={{
                                width: '230px',
                                minWidth: '190px',
                                height: '180px',
                                margin: '0 20',
                                borderRadius: '30px',
                                border: 'solid 3px #aaa',
                                background: '#fff',
                            }}
                        >
                            <LinkBlock
                                component={ReactLink}
                                to='/trends'
                                color='primary'
                                underline='none'
                                sx={{
                                    width: '230px',
                                    minWidth: '190px',
                                }}
                            >
                                <Button
                                    sx={{
                                        borderRadius: '20px',
                                        width: '100%',
                                        padding: '10 40',
                                    }}
                                    variant='standard'
                                >
                                    <Box>
                                        <Box>
                                            <TrendingUpIcon sx={{fontSize: 120}} />
                                        </Box>
                                        <Box
                                            sx={{
                                                color: 'black',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                            }}
                                        >
                                            トレンド
                                        </Box>
                                    </Box>
                                </Button>
                            </LinkBlock>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid container justifyContent='center' sx={{textAlign: 'left'}}>
                    <Grid item xs={12} sx={{margin: '10 0 10 0', fontSize: 14, color: '#666'}}>
                        <ul>
                            <li>cockpitにご意見、ご要望等ございましたら、cockpitのTeamsチャンネルにお寄せください。</li>
                            <li>
                                メンバーを追加したい場合は、各アカウントのつぶやきデスクのCP権限をお持ちの方が、
                                <Link target='_blank' href={`https://nhk-admin.okta.com/admin/groups`}>
                                    Okta管理画面
                                </Link>
                                から追加してください（管理者変更は
                                <Link
                                    target='_blank'
                                    href={`https://forms.office.com/pages/responsepage.aspx?id=A-r_51d0j0OcXcmi0ESawFNWk65-HcJAul3DlGXOFvtUREsyM1ZSWkJZVDJJRk82UVdZR1oxV1BSViQlQCN0PWcu`}
                                >
                                    こちら
                                </Link>
                                ）。
                            </li>
                            <li>
                                cockpitは
                                <Link target='_blank' href={`https://codeblue.cb.dev.nhk.jp`}>
                                    codeblue
                                </Link>
                                の枠組みで、メディアイノベーションセンター、デジタルセンターの職員によって、業務の20%という制限の下開発しています。返答にはお時間をいただく場合があります。ご了承ください。
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
export default Top;
