import axios from 'axios';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {createEmbeddingContext} from 'amazon-quicksight-embedding-sdk';
import {StateConstantContext} from '../constants/StateConstant';
import IncrementalSearch from '../components/IncrementalSearch';
import ScreenshotMonitorIcon from '@mui/icons-material/ScreenshotMonitor';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import {Button} from '../components/Button';
import {GetAuthorIdData} from '../components/getAuthorIdData';

const InstaAnalysis = () => {
    const [boardData, setBoardData] = React.useState('');
    const [dashBoard, setDashBoard] = React.useState('');
    const [dashBoardUrl, setDashBoardUrl] = React.useState('');
    const [selectedUsername, setSelectedUsername] = React.useState('');
    const [embeddingContext, setEmbeddingContext] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();
    const channel = 1;
    const [name, setName] = React.useState(new URLSearchParams(useLocation().search).get('name'));
    const [dashBoardNum, setDashBoardNum] = React.useState(new URLSearchParams(useLocation().search).get('dashboard') || '1');
    const [fromDate, setFromDate] = React.useState(new URLSearchParams(useLocation().search).get('fromDate'));
    const [toDate, setToDate] = React.useState(new URLSearchParams(useLocation().search).get('toDate'));
    const {authorIdIg, setAuthorIdIg, defaultAuthorIdIg, authorIdDataIg, idToken} = React.useContext(StateConstantContext);
    const [screenshotPopoverAnchorEl, setScreenshotPopoverAnchorEl] = React.useState(null);

    React.useEffect(() => {
        if (name) {
            setAuthorIdIg(name);
        }
    }, []);

    React.useEffect(() => {
        if (boardData != '' && dashBoard == '' && selectedUsername) {
            embeddedDashboard();
        }
    }, [boardData, selectedUsername]);

    // dashBoardUrlが更新されると、ダッシュボードのデータを取得する
    React.useEffect(() => {
        if (dashBoardUrl != '') getBoardData();
    }, [dashBoardUrl]);

    React.useEffect(() => {
        if (authorIdDataIg.length != 0 && dashBoardNum != '') {
            setAuthorIdIg(name || authorIdIg || defaultAuthorIdIg || '');
        }
    }, [authorIdDataIg]);

    // authorIdが変更される、またはauthorIdDataを取得したタイミングで、authorIdに対応するusernameを取り出す
    React.useEffect(() => {
        if (authorIdIg != '' && authorIdDataIg.length != 0) {
            authorIdToAccountName(authorIdIg);
            setName(authorIdIg);
        }
    }, [authorIdIg, authorIdDataIg]);

    const [authorIdDataIgLabels, setAuthorIdDataIgLabels] = React.useState('');
    const [authorIdDataIgValues, setAuthorIdDataIgValues] = React.useState('');
    React.useEffect(() => {
        if (authorIdDataIg !== '') {
            setAuthorIdDataIgLabels(authorIdDataIg.map((item) => item.name));
            setAuthorIdDataIgValues(authorIdDataIg.map((item) => item.channelId));
        }
    }, [authorIdDataIg]);

    // selectedUsernameが変更されるとダッシュボードを更新し、AuthorIdをセット
    React.useEffect(() => {
        if (authorIdDataIg != '') {
            updateDashboard();
            accountNameToAuthorId(selectedUsername);
        }
    }, [selectedUsername]);

    // dashBoardNumが変更されると、現在のダッシュボードを削除
    React.useEffect(() => {
        dashboardSet(dashBoardNum);
        deleteDashboard();
    }, [dashBoardNum]);

    React.useEffect(() => {
        if (idToken) {
            navigate(
                `/insta-analysis?${authorIdIg ? 'name=' + authorIdIg : ''}${dashBoardNum ? '&dashboard=' + dashBoardNum : ''}${fromDate ? '&fromDate=' + fromDate : ''}${
                    toDate ? '&toDate=' + toDate : ''
                }`,
            );
        }
    }, [idToken, authorIdIg, dashBoardNum, fromDate, toDate]);

    // ダッシュボードのパラメータ更新
    const updateDashboard = async () => {
        if (embeddingContext != '') {
            dashBoard.setParameters([
                {
                    Name: 'account',
                    Values: selectedUsername,
                },
                {
                    Name: 'fromDate',
                    Values: fromDate,
                },
                {
                    Name: 'toDate',
                    Values: toDate,
                },
            ]);
        }
    };

    const embeddedDashboard = async () => {
        let isLoaded = false;
        const context = await createEmbeddingContext();
        setEmbeddingContext(context);
        const options = {
            url: boardData,
            container: '#quicksightEmbed',
            width: '100%',
            height: '100%',
        };
        const contentOptions = {
            parameters: [
                {
                    Name: 'account',
                    Values: selectedUsername,
                },
                {
                    Name: 'fromDate',
                    Values: fromDate,
                },
                {
                    Name: 'toDate',
                    Values: toDate,
                },
            ],
            onMessage: async (messageEvent, experienceMetadata) => {
                if (messageEvent.eventName === 'CONTENT_LOADED') {
                    isLoaded = true;
                } else if (messageEvent.eventName === 'PARAMETERS_CHANGED') {
                    if (isLoaded) {
                        if (messageEvent.message.changedParameters[0].Name == 'fromDate') {
                            setFromDate(messageEvent.message.changedParameters[0].Values[0]);
                        } else if (messageEvent.message.changedParameters[0].Name == 'toDate') {
                            setToDate(messageEvent.message.changedParameters[0].Values[0]);
                        } else if (messageEvent.message.changedParameters[0].Name == 'account') {
                            setSelectedUsername(messageEvent.message.changedParameters[0].Values[0]);
                        }
                    }
                }
            },
        };
        const dashboard = await context.embedDashboard(options, contentOptions);
        setDashBoard(dashboard);
    };

    const getBoardData = async () => {
        if (idToken) {
            setIsLoading(true);
            const GET_BOARD_URL = process.env.REACT_APP_API_URL + 'dashboard-url?dashboardId=' + dashBoardUrl;
            const boardResponse = await axios.get(GET_BOARD_URL, {headers: {Authorization: idToken}});
            if (boardResponse.status == 200) {
                setBoardData(JSON.parse(JSON.stringify(boardResponse.data.EmbedUrl)));
                setIsLoading(false);
            }
        }
    };

    // authorIdから選択したusernameを抽出してselectedUsernameにセット
    const authorIdToAccountName = (e) => {
        authorIdDataIg.forEach((g) => {
            if (g.channelId === e) {
                setSelectedUsername(g.name);
            }
        });
    };

    const accountNameToAuthorId = (e) => {
        authorIdDataIg.forEach((g) => {
            if (g.name === e) {
                setAuthorIdIg(g.channelId);
            }
        });
    };

    const deleteDashboard = () => {
        const iframe = document.getElementsByClassName('quicksight-embedding-iframe');
        if (iframe.length != 0) {
            iframe[0].remove();
            setDashBoard('');
        }
    };

    // ダッシュボード選択のボタンを押した時の挙動
    const buttonHandleChange = (event, newBoard) => {
        if (newBoard !== null) {
            setDashBoardNum(newBoard);
            dashboardSet(newBoard);
        }
    };

    // URLからダッシュボードを取得した時の挙動
    const dashboardSet = (boardNum) => {
        if (boardNum === '1') {
            setDashBoardUrl(process.env.REACT_APP_DASHBOARD_INSTA_ANALYSIS_ID_1);
        } else if (boardNum === '2') {
            setDashBoardUrl(process.env.REACT_APP_DASHBOARD_INSTA_ANALYSIS_ID_2);
        } else if (boardNum === '3') {
            setDashBoardUrl(process.env.REACT_APP_DASHBOARD_INSTA_ANALYSIS_ID_3);
        } else if (boardNum == '4') {
            setDashBoardUrl(process.env.REACT_APP_DASHBOARD_INSTA_ANALYSIS_ID_4);
        } else if (boardNum == '10') {
            setDashBoardUrl(process.env.REACT_APP_DASHBOARD_ANALYSIS_DEV_ID_2);
        }
    };

    const handleScreenshotPopoverClick = (event) => {
        setScreenshotPopoverAnchorEl(event.currentTarget);
    };

    const handleScreenshotPopoverClose = () => {
        setScreenshotPopoverAnchorEl(null);
    };

    const linkToDefault = () => {
        navigate(`/insta-analysis?${name ? 'name=' + name : ''}${dashBoardNum ? '&dashboard=' + dashBoardNum : ''}`);
        window.location.reload();
        setFromDate('');
        setToDate('');
    };

    const screenshotPopoverOpen = Boolean(screenshotPopoverAnchorEl);
    const screenshotPopoverId = screenshotPopoverOpen ? 'simple-popover' : undefined;

    return (
        <>
            <GetAuthorIdData channel={channel} />
            <Grid
                container
                justifyContent='center'
                sx={{
                    width: '100%',
                    marginLeft: 'auto',
                    textAlign: 'center',
                    padding: {xs: '0px', sm: '4px 2.5vw 0 3vw'},
                }}
                // spacing={2}
            >
                <Grid item md={4} xs={6}>
                    <Box sx={{width: 'auto', maxWidth: 300, margin: '0 auto', padding: '0 8px 8px'}}>
                        <IncrementalSearch
                            labelKeys={authorIdDataIgLabels}
                            labelValues={authorIdDataIgValues}
                            defaultLabelValue={authorIdIg}
                            labelValue={authorIdIg}
                            setLabelValue={setAuthorIdIg}
                            description={'アカウントを選択'}
                        />
                    </Box>
                </Grid>
                <Grid item md={2} xs={6}>
                    <Button
                        onClick={linkToDefault}
                        sx={{
                            width: '100%',
                            minWidth: '100px',
                            maxWidth: '200px',
                            height: 40,
                            margin: '10px 0',
                        }}
                    >
                        <Box
                            sx={{
                                color: 'black',
                                fontWeight: 'bold',
                                fontSize: 12,
                            }}
                        >
                            日付初期化
                        </Box>
                    </Button>
                </Grid>

                <Grid item md={6} xs={12} sx={{position: 'relative', textAlign: 'right'}}>
                    <Box sx={{margin: '0 auto', display: {xs: 'flex', md: 'block'}, justifyContent: 'center'}}>
                        <ToggleButtonGroup
                            sx={{
                                position: 'relative',
                                margin: {xs: '0px auto -2px', md: '20px 12px -2px auto', lg: '15px 12px -2px auto'},
                                right: {xs: '0px', sm: '20px', lg: '30px'},
                                height: {xs: '48px', md: '46px', lg: '52px'},
                                '&& .MuiToggleButtonGroup-grouped': {
                                    border: 'solid 1px #2E5077',
                                    color: '#000',
                                    fontSize: {xs: '12px', lg: '16px'},
                                    padding: 'auto 15px',
                                    borderRadius: '10px 10px 0 0',
                                    minWidth: {xs: '60px', sm: '80px', lg: '100px'},
                                },
                                '&& .mui-selected, && .mui-selected:hover': {
                                    bgcolor: '#2F5077',
                                    border: 'solid 1px #2F5077',
                                    color: '#fff',
                                },
                                '&& .MuiToggleButtonGroup-middleButton, && .MuiToggleButtonGroup-lastButton': {
                                    borderLeft: 'solid 1px #2E5077',
                                },
                            }}
                            color='primary'
                            value={dashBoardNum}
                            exclusive
                            onChange={buttonHandleChange}
                            aria-label='Platform'
                        >
                            <ToggleButton value='1'>サマリ</ToggleButton>
                            <ToggleButton value='2'>投稿一覧</ToggleButton>
                            <ToggleButton value='3'>投稿詳細</ToggleButton>
                            <ToggleButton value='4'>フォロワー推移</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Box sx={{position: 'absolute', top: '-10px', right: '0', margin: '0 10px'}}>
                        <Box
                            sx={{
                                position: 'relative',
                                bottom: '3px',
                                right: '40px',
                                textDecoration: 'underline',
                                fontSize: '14px',
                                '&:hover': {
                                    cursor: 'pointer',
                                },
                            }}
                            onClick={() => navigate('/analysis')}
                        >
                            X用はこちら
                        </Box>
                        <Tooltip title={`スクリーンショットの撮り方`} placement='left' arrow sx={{position: 'absolute', top: '  -10px', right: '-10px'}}>
                            <IconButton aria-describedby='how to get screenshot' onClick={handleScreenshotPopoverClick}>
                                <ScreenshotMonitorIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Popover
                        id={screenshotPopoverId}
                        open={screenshotPopoverOpen}
                        anchorEl={screenshotPopoverAnchorEl}
                        onClose={handleScreenshotPopoverClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        sx={{
                            width: 'auto',
                            padding: '0px 10px',
                        }}
                    >
                        <Box sx={{padding: '0px 10px'}}>
                            <p style={{margin: '4px 0'}}>Windows：Windowsキー + Shiftキー + S</p>
                            <p style={{margin: '4px 0'}}>Mac： Commandキー + Shiftキー + 4</p>
                        </Box>
                    </Popover>
                </Grid>

                {isLoading ? (
                    <Box sx={{width: '100%', height: {xs: 'calc(100vh - 230px)', md: 'calc(100vh - 184px)'}, border: 'solid 1px #2E5077', zIndex: '1', backgroundColor: '#fff', borderRadius: '5px'}}>
                        <CircularProgress sx={{marginTop: '200px'}} />
                    </Box>
                ) : (
                    <Box
                        id='quicksightEmbed'
                        sx={{
                            width: '100%',
                            height: {xs: 'calc(100vh - 230px)', md: 'calc(100vh - 184px)'},
                            border: 'solid 1px #2E5077',
                            zIndex: '1',
                            backgroundColor: '#fff',
                            borderRadius: '5px',
                            paddingRight: '1px',
                            '&& .quicksight-embedding-iframe': {
                                borderRadius: '5px',
                            },
                        }}
                    ></Box>
                )}
            </Grid>
        </>
    );
};
export default InstaAnalysis;
