import * as React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import {Auth} from 'aws-amplify';

export default function FreeSoloSearch(props) {
    Auth.currentSession(); // IDトークンが切れている場合は更新
    let options = [];
    if (props.labelKeys.length == props.labelValues.length && props.labelValues.length != 0 && props.labelValues != undefined) {
        options = props.labelKeys.map((labelKey, i) => {
            if (labelKey) {
                return {
                    key: labelKey,
                    value: props.labelValues[i],
                };
            }
            return;
        });
    }
    // const handleChange = (value) => {
    //     props.setLabelValue(value);
    // };

    return (
        <Stack
            spacing={2}
            sx={{
                width: 350,
            }}
        >
            <Autocomplete
                freeSolo
                openOnFocus
                id='free-solo'
                disableClearable
                options={options.map((option) => option.key)}
                loading={true}
                loadingText='Now loading ...'
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.description}
                        key='free-solo-text'
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                        sx={{
                            '.MuiOutlinedInput-notchedOutline': {
                                borderRadius: '15px',
                                borderColor: '#2E5077',
                            },
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                border: 'solid 2px',
                            },
                        }}
                        variant='outlined'
                        onBlur={(event) => {
                            props.setLabelValue(event.target.value);
                        }}
                    />
                )}
                disabled={props.disabled}
                onChange={(event, newValue, reason) => {
                    props.setLabelValue(newValue);
                }}
                // onInputChange={(_, newValue, reason) => {
                //     console.log({reason});
                //     console.log(newValue);
                //     props.setLabelValue(newValue);
                // }}
                value={props.labelValue}
            />
        </Stack>
    );
}
