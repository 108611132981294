import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {Link as ReactLink} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import Top from './data/js/pages/Top';
import Analysis from './data/js/pages/Analysis';
import InstaAnalysis from './data/js/pages/InstaAnalysis';
import AnalysisDev from './data/js/pages/AnalysisDev';
import Admin from './data/js/pages/Admin';
import CheckLimit from './data/js/pages/CheckLimit';
import SocialTrends from './data/js/pages/SocialTrends';
import GoogleTrends from './data/js/pages/GoogleTrends';
import SetDefAcc from './data/js/pages/SetDefAcc';
import NotFound from './data/js/pages/NotFound';
import Tags from './data/js/pages/Tags';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import {styled, useTheme} from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SellIcon from '@mui/icons-material/Sell';
import Popover from '@mui/material/Popover';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import cockpitIcon from './data/img/cockpit-white-500x100.png';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import './data/css/style.css';
import Authenticate from './data/js/components/Auth';
// import {CredentialContext} from './data/js/components/Auth';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import {Helmet} from 'react-helmet';
import StateContext from './data/js/constants/StateConstant';
import {StateConstantContext} from './data/js/constants/StateConstant';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = createTheme({
        palette: {
            primary: {
                light: '#b6eeff',
                main: '#2E5077',
                dark: '#4d8ccc',
                contrastText: '#fff',
            },
            secondary: {
                light: '#ffffff',
                main: '#f6f6f6',
                dark: '#aeaeae',
                contrastText: '#000',
            },
        },
        mode: prefersDarkMode ? 'dark' : 'light',
    });
    return (
        <ThemeProvider theme={theme}>
            <Helmet>
                <script type='text/javascript'>
                    {`
            (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "kyyzex0v6i");
            `}
                </script>
            </Helmet>
            <StateContext>
                <Authenticate>
                    <Header />
                </Authenticate>
            </StateContext>
        </ThemeProvider>
    );
};

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(6)} + 1px)`,
    border: 'none',
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(6)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const Header = (props) => {
    const {credential, attribute} = React.useContext(StateConstantContext);
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [analysisHandleOpen, setAnalysisHandleOpen] = React.useState(false);
    const [trendsHandleOpen, setTrendsHandleOpen] = React.useState(false);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    useEffect(() => {
        const cognitoCustomKeys = {
            X: 'custom:def_account_x',
            INSTAGRAM: 'custom:def_account_ig',
        };
        if (attribute) {
            if (
                Object.values(cognitoCustomKeys)
                    .map((e) => Object.keys(attribute.attributes).some((f) => f.includes(e)))
                    .some((e) => e === false)
            ) {
                navigate('/default_account');
            }
        }
    }, [attribute]);

    if (!credential) {
        return;
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const analysisHandleClick = () => {
        setAnalysisHandleOpen(!analysisHandleOpen);
    };

    const analysisHandleClickClose = () => {
        setAnalysisHandleOpen(false);
    };

    const trendsHandleClick = () => {
        setTrendsHandleOpen(!trendsHandleOpen);
    };

    const trendsHandleClickClose = () => {
        setTrendsHandleOpen(false);
    };

    const openPopup = Boolean(anchorEl);

    // サインアウト
    const logout = () => {
        window.location.href = 'https://' + process.env.REACT_APP_DOMAIN + '/logout?client_id=' + process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID + '&logout_uri=' + process.env.REACT_APP_LOGOUT_URL;
    };

    return (
        <Box sx={{display: 'flex'}}>
            <AppBar position='fixed' open={open} elevation={0}>
                <Toolbar>
                    <IconButton
                        color='inherit'
                        aria-label='option-drawer'
                        onClick={handleDrawerOpen}
                        edge='start'
                        sx={{
                            marginRight: 5,
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Link component={ReactLink} to='/' color='inherit' underline='none'>
                        <img src={cockpitIcon} href='/' alt='cockpit' style={{width: '150px'}}></img>
                    </Link>
                    {credential != '' && (
                        <>
                            <Box
                                sx={{
                                    margin: '0 0 0 auto',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                aria-describedby={'option drawer open'}
                                variant='contained'
                                onClick={handleClick}
                            >
                                ようこそ！ {credential.attributes.family_name + ' ' + credential.attributes.given_name} さん
                            </Box>
                            <Popover
                                id={'optionDrawer'}
                                open={openPopup}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: '4px 10px',
                                    }}
                                >
                                    <Link component={ReactLink} to='/default_account' color='inherit' underline='none'>
                                        <Box
                                            sx={{
                                                marginRight: '20px',
                                                '&:hover': {
                                                    cursor: 'pointer',
                                                    backgroundColor: '#eee',
                                                },
                                            }}
                                            onClick={handleClose}
                                        >
                                            デフォルトアカウント選択
                                        </Box>
                                    </Link>
                                    <Box
                                        sx={{
                                            marginRight: '20px',
                                            '&:hover': {
                                                cursor: 'pointer',
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={logout}
                                    >
                                        ログアウト
                                    </Box>
                                </Box>
                            </Popover>
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Drawer
                variant='permanent'
                open={open}
                sx={{display: {xs: 'none', md: 'flex'}, ...(open && {display: 'flex'})}}
                PaperProps={{
                    sx: {
                        backgroundColor: '#f2f2f2',
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton
                        onClick={() => {
                            handleDrawerClose();
                            analysisHandleClickClose();
                            trendsHandleClickClose();
                        }}
                    >
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    <ListItem
                        disablePadding
                        sx={{
                            display: 'block',
                            // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                            '&& .mui-selected, && .mui-selected:hover': {
                                bgcolor: '#ddd',
                                '&, & .MuiSvgIcon-root': {
                                    color: '#2F5077',
                                },
                            },
                            '& .muilistitembutton-root:hover': {
                                bgcolor: '#ddd',
                                '&, & .muilistitemicon-root': {
                                    color: '#2F5077',
                                },
                            },
                        }}
                        component={ReactLink}
                    >
                        <ListItemButton
                            onClick={() => {
                                analysisHandleClick();
                                handleDrawerOpen();
                            }}
                            selected={'/analysis' === pathname || '/insta-analysis' === pathname}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 0 : 5,
                                    justifyContent: 'center',
                                    marginLeft: '-4px',
                                }}
                            >
                                <AssessmentIcon sx={{color: '#999'}} />
                            </ListItemIcon>
                            <ListItemText
                                primary='ダッシュボード'
                                sx={{
                                    opacity: open ? 1 : 0,
                                    marginLeft: '4px',
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                }}
                            />
                            {analysisHandleOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={analysisHandleOpen} timeout='auto' unmountOnExit>
                        <ListItem
                            component={ReactLink}
                            to='/analysis'
                            disablePadding
                            onClick={() => {
                                handleDrawerClose();
                                analysisHandleClickClose();
                            }}
                            sx={{
                                display: 'block',
                                // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                                '&& .mui-selected, && .mui-selected:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .MuiSvgIcon-root': {
                                        color: '#2F5077',
                                    },
                                },
                                '& .muilistitembutton-root:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .muilistitemicon-root': {
                                        color: '#2F5077',
                                    },
                                },
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                selected={'/analysis' === pathname}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : 'auto',
                                        ml: open ? 2 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LooksOneIcon sx={{color: '#999'}} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='X（旧Twitter）'
                                    sx={{opacity: open ? 1 : 0}}
                                    primaryTypographyProps={{
                                        color: 'black',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            component={ReactLink}
                            to='/insta-analysis'
                            disablePadding
                            onClick={() => {
                                handleDrawerClose();
                                analysisHandleClickClose();
                            }}
                            sx={{
                                display: 'block',
                                // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                                '&& .mui-selected, && .mui-selected:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .MuiSvgIcon-root': {
                                        color: '#2F5077',
                                    },
                                },
                                '& .muilistitembutton-root:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .muilistitemicon-root': {
                                        color: '#2F5077',
                                    },
                                },
                            }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                selected={'/insta-analysis' === pathname}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : 'auto',
                                        ml: open ? 2 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LooksTwoIcon sx={{color: '#999'}} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='Instagram'
                                    sx={{opacity: open ? 1 : 0}}
                                    primaryTypographyProps={{
                                        color: 'black',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </Collapse>
                    <ListItem
                        disablePadding
                        sx={{
                            display: 'block',
                            '&& .mui-selected, && .mui-selected:hover': {
                                bgcolor: '#ddd',
                                '&, & .MuiSvgIcon-root': {
                                    color: '#2F5077',
                                },
                            },
                            '& .muilistitembutton-root:hover': {
                                bgcolor: '#ddd',
                                '&, & .muilistitemicon-root': {
                                    color: '#2F5077',
                                },
                            },
                        }}
                        component={ReactLink}
                        to='/tags'
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            selected={'/tags' === pathname}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <SellIcon sx={{color: '#999'}} />
                            </ListItemIcon>
                            <ListItemText
                                primary='タグ付け'
                                sx={{opacity: open ? 1 : 0}}
                                primaryTypographyProps={{
                                    color: 'black',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        disablePadding
                        sx={{
                            display: 'block',
                            // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                            '&& .mui-selected, && .mui-selected:hover': {
                                bgcolor: '#ddd',
                                '&, & .MuiSvgIcon-root': {
                                    color: '#2F5077',
                                },
                            },
                            '& .muilistitembutton-root:hover': {
                                bgcolor: '#ddd',
                                '&, & .muilistitemicon-root': {
                                    color: '#2F5077',
                                },
                            },
                        }}
                        component={ReactLink}
                        to='/checklimit'
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                // justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                marginLeft: '-8px',
                            }}
                            selected={'/checklimit' === pathname}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AccessAlarmIcon sx={{color: '#999'}} />
                            </ListItemIcon>
                            <ListItemText
                                primary='投稿期限'
                                sx={{
                                    opacity: open ? 1 : 0,
                                    marginLeft: '-9px',
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                }}
                            />
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        disablePadding
                        sx={{
                            display: 'block',
                            // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                            '&& .mui-selected, && .mui-selected:hover': {
                                bgcolor: '#ddd',
                                '&, & .MuiSvgIcon-root': {
                                    color: '#2F5077',
                                },
                            },
                            '& .muilistitembutton-root:hover': {
                                bgcolor: '#ddd',
                                '&, & .muilistitemicon-root': {
                                    color: '#2F5077',
                                },
                            },
                        }}
                        component={ReactLink}
                    >
                        <ListItemButton
                            onClick={() => {
                                trendsHandleClick();
                                handleDrawerOpen();
                            }}
                            selected={'/trends' === pathname || '/g-trends' === pathname}
                        >
                            <ListItemIcon>
                                <TrendingUpIcon
                                    sx={{
                                        color: '#999',
                                        marginLeft: '-4px',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary='トレンド'
                                sx={{
                                    opacity: open ? 1 : 0,
                                    marginLeft: '-20px',
                                }}
                                primaryTypographyProps={{
                                    color: 'black',
                                }}
                            />
                            {trendsHandleOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                    </ListItem>
                    <Collapse in={trendsHandleOpen} timeout='auto' unmountOnExit>
                        <ListItem
                            disablePadding
                            onClick={() => {
                                handleDrawerClose();
                                trendsHandleClickClose();
                            }}
                            sx={{
                                display: 'block',
                                // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                                '&& .mui-selected, && .mui-selected:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .MuiSvgIcon-root': {
                                        color: '#2F5077',
                                    },
                                },
                                '& .muilistitembutton-root:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .muilistitemicon-root': {
                                        color: '#2F5077',
                                    },
                                },
                            }}
                            component={ReactLink}
                            to='/trends'
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                selected={'/trends' === pathname}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : 'auto',
                                        ml: open ? 2 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LooksOneIcon sx={{color: '#999'}} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='ソーシャル'
                                    sx={{opacity: open ? 1 : 0}}
                                    primaryTypographyProps={{
                                        color: 'black',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                        <ListItem
                            disablePadding
                            onClick={() => {
                                handleDrawerClose();
                                trendsHandleClickClose();
                            }}
                            sx={{
                                display: 'block',
                                // https://www.appsloveworld.com/reactjs/100/13/how-to-set-selected-and-hover-color-of-listitem-in-mui
                                '&& .mui-selected, && .mui-selected:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .MuiSvgIcon-root': {
                                        color: '#2F5077',
                                    },
                                },
                                '& .muilistitembutton-root:hover': {
                                    bgcolor: '#ddd',
                                    '&, & .muilistitemicon-root': {
                                        color: '#2F5077',
                                    },
                                },
                            }}
                            component={ReactLink}
                            to='/g-trends'
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                                selected={'/g-trends' === pathname}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 2 : 'auto',
                                        ml: open ? 2 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <LooksTwoIcon sx={{color: '#999'}} />
                                </ListItemIcon>
                                <ListItemText
                                    primary='グーグル'
                                    sx={{opacity: open ? 1 : 0}}
                                    primaryTypographyProps={{
                                        color: 'black',
                                    }}
                                />
                            </ListItemButton>
                        </ListItem>
                    </Collapse>
                </List>
            </Drawer>
            <Box component='main' sx={{flexGrow: 1, p: 3}}>
                <DrawerHeader />
                {credential && <Content />}
            </Box>
        </Box>
    );
};

const Content = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Top />} />
            <Route path={'/analysis'} element={<Analysis />} />
            <Route path={'/insta-analysis'} element={<InstaAnalysis />} />
            <Route path={'/analysis-dev'} element={<AnalysisDev />} />
            <Route path={'/admin'} element={<Admin />} />
            <Route path={'/checklimit'} element={<CheckLimit />} />
            <Route path={'/trends'} element={<SocialTrends />} />
            <Route path={'/default_account'} element={<SetDefAcc />} />
            <Route path={'/g-trends'} element={<GoogleTrends />} />
            <Route path={'/tags'} element={<Tags />} />
            <Route path={'/*'} element={<NotFound />} />
        </Routes>
    );
};

export default App;
