import * as React from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Carousel from 'react-material-ui-carousel';
import {styled} from '@mui/material/styles';
import {DataGrid} from '@mui/x-data-grid';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {TwitterTweetEmbed} from 'react-twitter-embed';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp'; // 左矢印アイコン
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'; // 右矢印アイコン
import ja from 'date-fns/locale/ja';
import {format} from 'date-fns';

import {StateConstantContext} from '../constants/StateConstant';
import {GetAuthorIdData} from '../components/getAuthorIdData';
import IncrementalSearch from '../components/IncrementalSearch';
import FreeSoloSearch from '../components/FreeSoloSearch';

const Tags = () => {
    // 変数定義
    const navigate = useNavigate();

    // アカウント選択周りの変数定義
    const [SNSTypeId, setSNSTypeId] = React.useState(0);
    const {authorIdX, setAuthorIdX, authorIdIg, setAuthorIdIg, defaultAuthorIdX, defaultAuthorIdIg, authorIdDataX, authorIdDataIg, name, idToken} = React.useContext(StateConstantContext);
    const [authorId, setAuthorId] = React.useState('');
    const [authorIdsDict, setAuthorIdsDict] = React.useState('');
    const [authorIdsDictKeys, setAuthorIdsDictKeys] = React.useState('');
    const [authorIdsDictValues, setAuthorIdsDictValues] = React.useState('');

    // 日付選択周りの変数定義
    const jstNow = new Date();
    const jstPast = new Date(new Date().setDate(jstNow.getDate() - 7));
    const defaultStartDateStr = jstPast.toLocaleDateString('ja-JP', {year: 'numeric', month: '2-digit', day: '2-digit'}).replaceAll('/', '-');
    const defaultEndDateStr = jstNow.toLocaleDateString('ja-JP', {year: 'numeric', month: '2-digit', day: '2-digit'}).replaceAll('/', '-');
    const [startDateStr, setStartDateStr] = React.useState(defaultStartDateStr);
    const [endDateStr, setEndDateStr] = React.useState(defaultEndDateStr);
    const [startDate, setStartDate] = React.useState(jstPast);
    const [endDate, setEndDate] = React.useState(jstNow);

    // タグ絞り込み周りの変数定義
    const [tagQueryKey] = React.useState('');
    const [tagQueryValue] = React.useState('');
    const [tagQueriesList, setTagQueriesList] = React.useState([]);

    // 表周りの変数定義
    const url = process.env.REACT_APP_API_URL;
    const [postItems, setPostItems] = React.useState([]);
    const [tableKey, setTableKey] = React.useState('');
    const [tableRows, setTableRows] = React.useState([]);
    const [selectedTableRowIds, setSelectedTableRowIds] = React.useState([]);

    const [pageSize, setPageSize] = React.useState(10);
    const [postsNum, setPostsNum] = React.useState(0);
    const [nowPage, setNowPage] = React.useState(0);
    const [maxPage, setMaxPage] = React.useState(-1);
    const [isGetPosts, setIsGetPosts] = React.useState(Boolean); // 取得済みのデータで賄えるか新規データを取得する必要があるかのフラグ
    const [initState, setInitState] = React.useState(Boolean);

    // タグ登録周りの変数定義
    const tagKeys = ['番組名', '放送回', '誘導先'];
    const [isEditTagButton, setIsEditTagButton] = React.useState(Boolean);
    const [isEditTagModal, setIsEditTagModal] = React.useState(Boolean);
    const [programTag, setProgramTag] = React.useState('');
    const [episodeTag, setEpisodeTag] = React.useState('');
    const [targetTag, setTargetTag] = React.useState('');
    const [isDeleteTagsChecked, setIsDeleteTagsChecked] = React.useState([Boolean, Boolean, Boolean]);
    const [isDeleteTagButton, setIsDeleteTagButton] = React.useState(Boolean);

    // タグ編集画面での投稿カード表示用の変数定義

    // 初回読み込み時 or 変数更新時に実行
    React.useEffect(() => {
        setSNSTypeId(SNSTypeId);
        if (SNSTypeId === 0) {
            setAuthorIdsDict(authorIdDataX);
        } else if (SNSTypeId === 1) {
            setAuthorIdsDict(authorIdDataIg);
        }
    }, [SNSTypeId, authorIdDataX, authorIdDataIg]);

    React.useEffect(() => {
        if (authorIdsDict !== '') {
            setAuthorIdsDictKeys(authorIdsDict.map((item) => item.name));
            setAuthorIdsDictValues(authorIdsDict.map((item) => item.channelId));
        }
    }, [authorIdsDict]);

    React.useEffect(() => {
        if (SNSTypeId === 0) {
            setAuthorId(authorIdX || name || defaultAuthorIdX || '');
        } else if (SNSTypeId === 1) {
            setAuthorId(authorIdIg || name || defaultAuthorIdIg || '');
        }
    }, [authorIdX, authorIdIg, defaultAuthorIdX, defaultAuthorIdIg, name]);

    React.useEffect(() => {
        if (authorId !== '') {
            navigate('/tags?name=' + authorId);
            setInitState(true);
            getTagQueriesList(authorId);
            if (SNSTypeId === 0 && authorId !== authorIdX) {
                setAuthorIdX(authorId);
            } else if (SNSTypeId === 1 && authorId !== authorIdIg) {
                setAuthorIdIg(authorId);
            }
        }
    }, [authorId]);

    React.useEffect(() => {
        if (authorId !== '' && startDateStr !== '' && endDateStr !== '') {
            if (startDate) setInitState(true);
        }
    }, [startDateStr, endDateStr]);

    React.useEffect(() => {
        if (authorId !== '') {
            setInitState(true);
        }
    }, [tagQueryKey, tagQueryValue]);

    React.useEffect(() => {
        if (isGetPosts && authorId !== '') {
            getPosts();
        } else {
            setIsGetPosts(false);
        }
    }, [isGetPosts]);

    React.useEffect(() => {
        if (postItems.length > 0) {
            getTableRows(postItems);
        }
    }, [postItems]);

    React.useEffect(() => {
        if (authorId !== '') {
            setInitState(true);
        }
    }, [pageSize]);

    React.useEffect(() => {
        if (maxPage < nowPage) {
            setMaxPage(nowPage);
            setIsGetPosts(true);
        }
    }, [nowPage]);

    React.useEffect(() => {
        if (initState) {
            setPostItems([]);
            setTableKey('');
            setTableRows([]);
            setSelectedTableRowIds([]);
            setPostsNum(0);
            setNowPage(0);
            setMaxPage(0);
            setProgramTag('');
            setEpisodeTag('');
            setTargetTag('');
            setIsEditTagButton(false);
            setIsEditTagModal(false);
            setIsGetPosts(true);
            setInitState(false);
        }
    }, [initState]);

    React.useEffect(() => {
        if (selectedTableRowIds.length > 0) {
            setIsEditTagButton(true);
        } else {
            setIsEditTagButton(false);
        }
    }, [selectedTableRowIds]);

    React.useEffect(() => {
        if (isDeleteTagsChecked.some((x) => x === true)) {
            setIsDeleteTagButton(true);
        } else {
            setIsDeleteTagButton(false);
        }
    }, [isDeleteTagsChecked]);

    // 投稿データの取得
    const getPosts = async () => {
        if (idToken && authorId !== '') {
            const GetPostsURL =
                url +
                'posts?author_id=' +
                authorId +
                '&page=' +
                nowPage +
                '&limit=' +
                pageSize +
                '&start_date=' +
                startDateStr +
                '&end_date=' +
                endDateStr +
                '&query_key=' +
                tagQueryKey +
                '&query_val=' +
                tagQueryValue;
            const postsResponse = await axios.get(GetPostsURL, {headers: {Authorization: idToken}});
            if (postsResponse.status == 200) {
                setPostItems(postsResponse.data.Items);
                if (postsNum === 0) {
                    setPostsNum(postsResponse.data.Count);
                }
                setIsGetPosts(false);
            }
        }
    };

    // あるアカウントで登録されているタグ一覧取得
    const getTagQueriesList = async (authorId) => {
        if (idToken && authorId) {
            // const tagQueryList = [];
            const GetTagQueriesURL = url + 'tags?authorId=' + authorId;
            const tagQueriesResponse = await axios.get(GetTagQueriesURL, {headers: {Authorization: idToken}});
            if (tagQueriesResponse.status == 200) {
                // tagQueryList.push(...Object.keys(tagsResponse.data));
                // setTagQueriesList(tagQueryList);
                setTagQueriesList(tagQueriesResponse.data);
            }
        }
    };

    // アカウント選択
    const SelectAuthor = (props) => {
        return (
            <IncrementalSearch
                labelKeys={props.authorNames}
                labelValues={props.authorIds}
                defaultLabelValue={props.defaultAuthorId}
                labelValue={props.selectedAuthorId}
                setLabelValue={props.setSelectedAuthorId}
                description={'アカウントを選択'}
            />
        );
    };

    // 日付選択のスタイル

    // 日付選択
    const SelectDate = (props) => {
        const dateHandleChange = (newValue) => {
            const formatDay = format(newValue, 'yyyy-MM-dd');
            props.setSelectedDate(formatDay);
            props.setDate(newValue);
        };

        const dateFieldStyles = {
            '.MuiOutlinedInput-notchedOutline': {
                borderRadius: '15px',
                borderColor: '#2E5077',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                border: 'solid 2px',
            },
        };

        return (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ja}
                dateFormats={{monthAndYear: 'yyyy年 MM月'}}
                localeText={{
                    previousMonth: '前月を表示',
                    nextMonth: '次月を表示',
                }}
            >
                <Box>
                    <DatePicker label={props.label} maxDate={props.maxDate} minDate={props.minDate} defaultValue={jstNow} value={props.date} sx={dateFieldStyles} onChange={dateHandleChange} />
                </Box>
            </LocalizationProvider>
        );
    };

    const convertUTCtoJST = (utcStr) => {
        // UTC文字列をDateオブジェクトに変換
        const utcDate = new Date(utcStr);

        // JSTへの変換 (UTC+9時間)
        const jstDate = new Date(utcDate.getTime() + 9 * 60 * 60 * 1000);

        // 年、月、日、時、分を取得
        const year = jstDate.getFullYear();
        const month = ('0' + (jstDate.getMonth() + 1)).slice(-2);
        const day = ('0' + jstDate.getDate()).slice(-2);
        const hours = ('0' + jstDate.getHours()).slice(-2);
        const minutes = ('0' + jstDate.getMinutes()).slice(-2);

        // YYYY-MM-DD HH:MM 形式の文字列を返す
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    // 表の中身
    const getTableRows = (pItems) => {
        const pRows = [];
        pItems.forEach((pItem, i) => {
            const convertedTime = convertUTCtoJST(pItem.created_at);
            pRows.push({
                id: nowPage * pageSize + i,
                postId: pItem.post_id,
                pId: pItem.id,
                text: pItem.text,
                created_at: convertedTime,
                tags: JSON.stringify(pItem.tags),
                programTag: pItem.tags[tagKeys[0]] ? JSON.stringify(pItem.tags[tagKeys[0]]).slice(1, -1) : '',
                episodeTag: pItem.tags[tagKeys[1]] ? JSON.stringify(pItem.tags[tagKeys[1]]).slice(1, -1) : '',
                targetTag: pItem.tags[tagKeys[2]] ? JSON.stringify(pItem.tags[tagKeys[2]]).slice(1, -1) : '',
            });
        });
        setTableRows(tableRows.concat(pRows));
    };

    // 表の外枠
    const createTable = () => {
        const columns = [
            {
                field: 'created_at',
                headerName: '投稿日時',
                headerAlign: 'center',
                align: 'center',
                minWidth: 10,
                flex: 1,
                sortable: false,
            },
            {
                field: 'text',
                headerName: '投稿内容',
                headerAlign: 'center',
                align: 'center',
                minWidth: 30,
                flex: 3,
                sortable: false,
            },
            {
                field: 'programTag',
                headerName: tagKeys[0],
                headerAlign: 'center',
                align: 'center',
                minWidth: 10,
                flex: 1,
                sortable: false,
            },
            {
                field: 'episodeTag',
                headerName: tagKeys[1],
                headerAlign: 'center',
                align: 'center',
                minWidth: 10,
                flex: 1,
                sortable: false,
            },
            {
                field: 'targetTag',
                headerName: tagKeys[2],
                headerAlign: 'center',
                align: 'center',
                minWidth: 10,
                flex: 1,
                sortable: false,
            },
        ];

        const StyledGridOverlay = styled('div')(() => ({
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        }));

        const customNoRowsOverlay = () => {
            return (
                <StyledGridOverlay>
                    <div style={{fontSize: 16}}>Now Loading...</div>
                </StyledGridOverlay>
            );
        };

        return (
            <DataGrid
                rows={tableRows}
                columns={columns}
                key={tableKey}
                rowCount={postsNum}
                paginationModel={{
                    page: Number(nowPage),
                    pageSize: Number(pageSize),
                }}
                pageSizeOptions={[10, 25, 50]}
                onRowSelectionModelChange={(selectedRowId) => {
                    setSelectedTableRowIds(selectedRowId);
                }}
                onPaginationModelChange={(page) => {
                    setNowPage(Number(page.page));
                    setPageSize(Number(page.pageSize));
                }}
                slots={{noRowsOverlay: customNoRowsOverlay}}
                loading={false}
                checkboxSelection={true}
                disableRowSelectionOnClick={false}
            />
        );
    };

    // タグ編集モーダルのON/OFF
    const handleEditTagModalOpen = () => {
        setIsEditTagModal(true);
        setIsDeleteTagsChecked([false, false, false]);
        setIsDeleteTagButton(false);
    };

    const handleEditTagModalClose = () => {
        setIsEditTagModal(false);
        setProgramTag('');
        setEpisodeTag('');
        setTargetTag('');
    };

    // タグ入力フォーム
    const InputTag = (props) => {
        const defaultTagValue = getCommonTagValue(props.rows, props.description);
        const registeredTagValuesList = Array.from(getRegisteredTagValues(props.description));

        const checked = (description) => {
            if (description == tagKeys[0]) {
                return isDeleteTagsChecked[0];
            } else if (description == tagKeys[1]) {
                return isDeleteTagsChecked[1];
            } else if (description == tagKeys[2]) {
                return isDeleteTagsChecked[2];
            }
        };
        const handleChange = (event) => {
            if (props.description == tagKeys[0]) {
                setIsDeleteTagsChecked([event.target.checked, isDeleteTagsChecked[1], isDeleteTagsChecked[2]]);
            } else if (props.description == tagKeys[1]) {
                setIsDeleteTagsChecked([isDeleteTagsChecked[0], event.target.checked, isDeleteTagsChecked[2]]);
            } else if (props.description == tagKeys[2]) {
                setIsDeleteTagsChecked([isDeleteTagsChecked[0], isDeleteTagsChecked[1], event.target.checked]);
            }
        };

        return (
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-end',
                            alignItems: 'flex-end',
                            alignContent: 'flex-end',
                            padding: 'auto',
                        }}
                    >
                        {props.description}
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            padding: 'auto',
                        }}
                    >
                        <FreeSoloSearch
                            labelKeys={registeredTagValuesList}
                            labelValues={registeredTagValuesList}
                            defaultLabelValue={defaultTagValue}
                            labelValue={props.defaultTag}
                            setLabelValue={props.setDefaultTag}
                            description={defaultTagValue}
                            disabled={isDeleteTagButton}
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            alignContent: 'center',
                            padding: 'auto',
                        }}
                    >
                        <Checkbox checked={checked(props.description)} disabled={defaultTagValue === ''} onChange={handleChange} />
                    </Box>
                </Grid>
            </Grid>
        );
    };

    const getCommonTagValue = (rows, description) => {
        const commonTagValues = rows.map((row, i) => JSON.parse(row.tags)[description]).filter((v) => v !== undefined);

        if (new Set(commonTagValues).size < 1) {
            return '';
        } else if (new Set(commonTagValues).size == 1) {
            return commonTagValues[0];
        } else {
            return '複数の値';
        }
    };

    const getRegisteredTagValues = (description) => {
        const isRegisteredTagKey = Object.keys(tagQueriesList).filter((k) => k === description);
        if (isRegisteredTagKey) {
            return new Set(tagQueriesList[description]);
        } else {
            return '';
        }
    };

    // 投稿のカード表示
    const embedPostCard = (i, id, tags) => {
        const j = JSON.parse(tags);
        return (
            <Box key={i}>
                {Object.keys(j).map((k, i) => (
                    <Box key={i} sx={{width: '100%', textAlign: 'center'}}>
                        {k}:{j[k]}
                    </Box>
                ))}
                <Box
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        height: '480px',
                        flexWrap: 'wrap',
                        overflow: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '10px',
                            height: '10px',
                            display: 'none',
                        },
                        '&:hover': {
                            '::-webkit-scrollbar': {
                                // display: 'inline',
                            },
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#d2d2d2',
                            borderRadius: '10px',
                        },
                    }}
                >
                    {id != '' ? <TwitterTweetEmbed key={id} tweetId={id} placeholder={<CircularProgress sx={{marginTop: '50px'}}></CircularProgress>} /> : <p>選択した投稿が表示されます</p>}
                </Box>
            </Box>
        );
    };

    const setEmbedPostCards = (rows) => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            height: '100%',
                            border: '2px solid #ddd',
                            borderRadius: '15px',
                        }}
                    >
                        <Carousel
                            NextIcon={<ArrowForwardIosSharpIcon />} // 矢印アイコンを別のアイコンに変更
                            PrevIcon={<ArrowBackIosSharpIcon />} // 矢印アイコンを別のアイコンに変更
                            autoPlay={false} // 自動でCarouselを動かすかどうか(true or false)
                            stopAutoPlayOnHover={false} // Carouselの上にマウスを置いている間、自動スクロールを継続するかどうか
                            interval={4000} // 自動でCarouselを動かす時間の間隔(ミリ秒単位)
                            animation={'slide'} // (fade or slide) Carouselのアニメーションの種類を変更
                            duration={500} // アニメーションの長さを定義
                            swipe={true} // スワイプで動かせるかどうか
                            navButtonsAlwaysVisible={true} // 常に矢印アイコンを表示するかどうか
                            cycleNavigation={true} // 最後のスライドから「次へ」の矢印アイコンを押した時に最初にスライドに動かせるようにするかどうか
                            fullHeightHover={true} // 次/前のボタンがItem要素の高さ全体をカバーし、ホバー時にボタンを表示するかどうか
                        >
                            {rows.map((row, i) => embedPostCard(i, row.id, row.tags))}
                        </Carousel>
                    </Box>
                </Grid>
            </Grid>
        );
    };

    // タグ編集
    const EditTags = () => {
        const selectedTableRows = [];
        tableRows.forEach((tableRow) => {
            if (selectedTableRowIds.includes(tableRow.id)) {
                selectedTableRows.push({
                    post_id: tableRow.postId,
                    id: tableRow.pId,
                    text: tableRow.text,
                    created_at: tableRow.created_at,
                    tags: tableRow.tags,
                });
            }
        });
        return (
            <Box
                sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    padding: '10 auto',
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                padding: 'auto',
                            }}
                        >
                            <h3 style={{fontSize: '24px', margin: '16px'}}>タグ編集</h3>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={5} sx={{display: 'flex', justifyContent: 'center'}}>
                        {setEmbedPostCards(selectedTableRows)}
                    </Grid>
                    <Grid item xs={7}>
                        <Box
                            sx={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                                padding: 'auto',
                                mt: 4,
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <Box
                                        sx={{
                                            justifyContent: 'center',
                                            display: 'flex',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            flexWrap: 'wrap',
                                            padding: '10 auto',
                                        }}
                                    >
                                        <p>設定したいタグを入力してください（一括登録可）</p>
                                        <p>
                                            <font color='2E5077'>
                                                <small>※タグ設定は担当アカウントの投稿のみに行ってください</small>
                                            </font>
                                        </p>
                                    </Box>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        alignContent: 'center',
                                        padding: 'auto',
                                    }}
                                >
                                    <p>
                                        <small>削除</small>
                                    </p>
                                </Grid>
                                <Grid item xs={12}>
                                    <InputTag rows={selectedTableRows} description={tagKeys[0]} defaultTag={programTag} setDefaultTag={setProgramTag} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputTag rows={selectedTableRows} description={tagKeys[1]} defaultTag={episodeTag} setDefaultTag={setEpisodeTag} />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputTag rows={selectedTableRows} description={tagKeys[2]} defaultTag={targetTag} setDefaultTag={setTargetTag} />
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{margin: '10 auto', display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button
                                            variant='contained'
                                            disabled={isDeleteTagButton}
                                            startIcon={<PublishIcon />}
                                            onClick={() => {
                                                putTags(selectedTableRowIds);
                                            }}
                                        >
                                            登録
                                        </Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{margin: '10 auto', display: 'flex', justifyContent: 'flex-start'}}>
                                        <Button
                                            variant='contained'
                                            disabled={!isDeleteTagButton}
                                            color='error'
                                            startIcon={<DeleteIcon />}
                                            onClick={() => {
                                                deleteTags(selectedTableRowIds);
                                            }}
                                        >
                                            削除
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    // タグの登録
    const putTags = async (rowIds) => {
        const putTagsURL = url + 'tags';
        const items = [];
        rowIds.forEach((rowId, i) => {
            if (programTag !== '') {
                items.push({
                    id: tableRows[rowId]['pId'],
                    tag_key: tagKeys[0],
                    tag_val: programTag,
                    author_id: authorId,
                    channel_type: SNSTypeId,
                    text: tableRows[rowId]['text'],
                    created_at: tableRows[rowId]['created_at'],
                });
            }
            if (episodeTag !== '') {
                items.push({
                    id: tableRows[rowId]['pId'],
                    tag_key: tagKeys[1],
                    tag_val: episodeTag,
                    author_id: authorId,
                    channel_type: SNSTypeId,
                    text: tableRows[rowId]['text'],
                    created_at: tableRows[rowId]['created_at'],
                });
            }
            if (targetTag !== '') {
                items.push({
                    id: tableRows[rowId]['pId'],
                    tag_key: tagKeys[2],
                    tag_val: targetTag,
                    author_id: authorId,
                    channel_type: SNSTypeId,
                    text: tableRows[rowId]['text'],
                    created_at: tableRows[rowId]['created_at'],
                });
            }
        });
        const body = {Items: items};
        const res = await axios.put(putTagsURL, body, {headers: {Authorization: idToken}});
        if (res.status == 200) {
            alert(rowIds.length + '件の投稿のタグ登録に成功しました');
            setInitState(true);
        } else {
            alert('タグの登録に失敗しました');
        }
    };

    // タグの削除
    const deleteTags = async (rowIds) => {
        const deleteTagsURL = url + 'tags';
        const body = {
            ids: rowIds.map((rowId, i) => tableRows[rowId]['pId']),
            tag_keys: tagKeys.filter((k, i) => isDeleteTagsChecked[i] === true),
        };
        const res = await axios.delete(deleteTagsURL, {data: body, headers: {Authorization: idToken}});
        if (res.status == 200) {
            alert(rowIds.length + '件の投稿のタグ削除に成功しました');
            setInitState(true);
        } else {
            alert('タグの削除に失敗しました');
        }
    };

    return (
        <Container>
            <GetAuthorIdData channel={SNSTypeId} />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            width: 'auto',
                            minWidth: 50,
                            maxWidth: 400,
                            margin: '20 auto',
                        }}
                    >
                        <SelectAuthor authorNames={authorIdsDictKeys} authorIds={authorIdsDictValues} defaultAuthorId={authorId} selectedAuthorId={authorId} setSelectedAuthorId={setAuthorId} />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={0} sm={2}></Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            width: 'auto',
                            minWidth: 25,
                            maxWidth: 200,
                            margin: '0 auto',
                        }}
                    >
                        <SelectDate label={'開始日'} date={startDate} setDate={setStartDate} setSelectedDate={setStartDateStr} maxDate={endDate} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box
                        sx={{
                            justifyContent: 'center',
                            width: 'auto',
                            minWidth: 25,
                            maxWidth: 200,
                            margin: '0 auto',
                            marginBottom: {xs: '10px', sm: '0px'},
                        }}
                    >
                        <SelectDate label={'終了日'} date={endDate} setDate={setEndDate} setSelectedDate={setEndDateStr} minDate={startDate} maxDate={jstNow} />
                    </Box>
                </Grid>
                <Grid item xs={0} sm={2}></Grid>
            </Grid>
            <Grid
                container
                sx={{
                    padding: {xs: '0px', sm: '20px 2.5vw 0 3vw'},
                }}
                spacing={2}
            >
                <Grid item xs={12} sm={12}>
                    <Box
                        sx={{
                            width: '100%',
                            height: 115 + 52 * pageSize,
                            maxHeight: 900,
                        }}
                    >
                        {createTable()}
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                sx={{
                    padding: {xs: '0px', sm: '4px 2.5vw 0 3vw'},
                }}
                spacing={1}
            >
                <Grid item xs={6} sm={4}>
                    <Box sx={{width: 'auto', minWidth: 100, maxWidth: 400, margin: '10 auto'}}>
                        <Button variant='contained' disabled={!isEditTagButton} startIcon={<EditIcon />} onClick={handleEditTagModalOpen}>
                            タグを編集
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={8}></Grid>
            </Grid>
            {/* 選択した投稿とタグ編集のモーダル表示 */}
            <Modal open={isEditTagModal} onClose={handleEditTagModalClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description' sx={{overflow: 'scroll'}}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        height: '80%',
                        // minHeight: '600px',
                        maxHeight: '900px',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        minWidth: '800px',
                        maxWidth: '1200px',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        overflow: 'auto',
                        borderRadius: '15px',
                    }}
                    component='form'
                    noValidate
                    autoComplete='off'
                >
                    <EditTags></EditTags>
                </Box>
            </Modal>
        </Container>
    );
};
export default Tags;
